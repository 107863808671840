import React, { useEffect } from "react";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle";
import Title from "../components/Title";

function Booking() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://api.booksalon.fi/sdk.js";
    // Delay the script loading by 1 second (adjust as needed)
    setTimeout(() => {
      document
        .getElementsByTagName("script")[0]
        .parentNode.insertBefore(
          script,
          document.getElementsByTagName("script")[0]
        );
    }, 1000);
    // Cleanup function
    return () => {
      // Cleanup code if needed
    };
  }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  return (
    <>
      <PageTitle>Ajanvaraus</PageTitle>
      {/* <div className="text-center">
        <Fade>
          <Title centered>Ajanvaraus</Title>
        </Fade>
      </div> */}
      <div
        className="booksalon-widget"
        data-salon-id="adelian-beauty"
        data-widget-height="1500"
      ></div>
    </>
  );
}

export default Booking;
