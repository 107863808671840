import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Fade({ bottom = false, children }) {
  let line = useRef(null);

  useEffect(() => {
    const el = line.current;

    gsap.from(el, {
      y: 160,
      stagger: 0.1,
      duration: 0.8,
      ease: "back",
    });
    // gsap.timeline().from(el, {
    //   y: 160,
    //   stagger: 0.1,
    //   duration: 0.8,
    //   ease: "back",
    // });
    //eslint-disable-next-line
  }, [line]);
  return <div ref={line}>{children}</div>;
}

export default Fade;
