import React from 'react';

function Container({ children, noPadding, ...rest }) {
  return (
    <div
      className={`container mx-auto ${noPadding ? `` : `px-8 md:py-28 py-10`}`}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Container;
