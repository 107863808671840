import React, { useEffect, useRef } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import MenuLink from "./MenuLink";

function Dialog({ showMenu, hideMenu }) {
  const wrapperRef = useRef(null);
  useEffect(() => {
    if (!showMenu) return;
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [showMenu]);

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      showMenu
    ) {
      hideMenu();
    }
  };

  const menuItems = [
    { path: "/", label: "Etusivu" },
    // { path: "/booking", label: "Ajanvaraus" },
    { path: "/trainings", label: "Koulutukset" },
    { path: "/pricing", label: "Hinnasto" },
    { path: "/about", label: "Henkilökunta" },
    { path: "/contact", label: "Yhteystiedot" },
  ];
  return (
    <>
      <div className={`overlay ${!showMenu && `overlay-hide`}`}></div>
      <div
        className={`menu-dialog-body table ${!showMenu && `menu-dialog-hide`}`}
        ref={wrapperRef}
      >
        <div className="w-full">
          <div className="float-right mt-5 mb-5 mr-3">
            <CloseLineIcon
              size={36}
              className="cursor-pointer"
              onClick={hideMenu}
            />
          </div>
        </div>
        <ul className="w-full p-0 menu menu-vertical">
          {menuItems.map((menu, key) => (
            <MenuLink
              path={menu.path}
              title={menu.label}
              onClick={hideMenu}
              bold
            />
          ))}
        </ul>
      </div>
    </>
  );
}

export default Dialog;
