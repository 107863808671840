import React from "react";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle.jsx";
import Title from "../components/Title";
import classicImage from "../images/classic.jpg";
import volumeImage from "../images/volume.jpg";
import combineImage from "../images/yhdistely.jpg";

function Trainings() {
  return (
    <>
      <PageTitle>Koulutukset</PageTitle>
      <Container>
        <Fade>
          <div style={{ maxWidth: "900px" }} className="mx-auto text-center">
            <div className="mx-auto">
              <Title centered>Koulutukset</Title>
            </div>
            <p className="mb-8">
              Koulutuksiin otetaan vain yksi oppilas kerralla. Haluan näin taata
              laadukkaan ja yksilöllisen opetuksen jokaiselle.
            </p>
            <p className="mb-8">
              Koulutuksen voit perua 7 vuorokautta ennen koulutuksen ajankohtaa
              veloituksetta. Sairaustapauksissa voit siirtää koulutuksen
              ajankohtaa (1) kerran max 3kk päähän. Alle 7vrk peruutetuista
              koulutuksista veloitamme 50% koulutuksen hinnasta + laskutuslisä
              5€.{" "}
            </p>
            <p>
              Kysy lisää koulutuksistamme{" "}
              <a href="mailto:niina@adelianbeauty.fi">niina@adelianbeauty.fi</a>
            </p>
          </div>
        </Fade>
      </Container>
      <div className="bg-white">
        <Container noPadding>
          <div className="grid grid-cols-1 md:grid-cols-5 auto-rows-max">
            <div className="col-span-2">
              <Fade>
                <img
                  src={classicImage}
                  alt="Klassiset ripset"
                  className="opacity-90"
                />
              </Fade>
            </div>

            <div className="flex items-center col-span-3 px-10 md:px-20 md:h-full">
              <div className="my-16 text-center md:text-left">
                <Fade bottom>
                  <Title sm>Klassiset ripset</Title>
                  <p className="mb-8 font-bold">2 päivää - 850€</p>
                  <p className="mb-8">
                    Tämä koulutus sopii sinulle, joka haluat aloittaa työn
                    ripsiteknikkona. Koulutukseen sisältyy aloituspaketti, jotta
                    pääset heti tekemään asiakkaita koulutuksen jälkeen.
                    Ensimmäisenä päivänä harjoitellaan heti jo mallille. 2
                    päivän koulutuksessa opit suunnittelemaan turvalliset
                    pidennykset asiakkaillesi, sekä ripsien huoltamisen.
                    Kehitystä seurataan myös lähettämällä kuvia kouluttajallesi
                    ja kouluttaja on tukenasi koko ripsiurasi aikana.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container noPadding>
        <div className="grid h-full grid-cols-1 md:grid-cols-5 auto-rows-max">
          <div className="flex items-center h-full col-span-3 px-10 md:px-20">
            <div className="my-16 text-center md:text-left">
              <Fade bottom>
                <Title sm>Volyymiripset</Title>
                <p className="mb-8 font-bold">1 päivä - 500€</p>
                <p className="mb-8">
                  Tässä koulutuksessa opettelemme tekemään volyymiripsiä.
                  Koulutus sopii sinulle, jos olet käynyt jo klassisten ripsien
                  koulutuksen ja haluat laajentaa osaamista volyymiripsiin.
                  Koulutuspäivänä käymme teoriaa läpi ja tehdään työ mallille.
                  Koulutus sisältää myös aloituspakkauksen, jotta sinun on
                  helppo jatkaa työskentelyä kotona. Kouluttajan tuki jatkuu
                  myös koulutuksesi jälkeen.
                </p>
              </Fade>
            </div>
          </div>

          <div className="col-span-2">
            <Fade>
              <img
                src={volumeImage}
                alt="Volyymiripset"
                style={{
                  height: "auto",
                }}
                className="opacity-90"
              />
            </Fade>
          </div>
        </div>
      </Container>
      <div className="bg-white">
        <Container noPadding style={{ height: "100%" }}>
          <div className="grid h-full grid-cols-1 md:grid-cols-5">
            <div className="h-full col-span-2">
              <Fade>
                <img
                  src={combineImage}
                  alt="Klassiset ripset"
                  className="opacity-90"
                />
              </Fade>
            </div>
            <div className="flex items-center h-full col-span-3 px-10 py-10 md:px-20">
              <div className="my-16 text-center hyphens-auto md:text-left">
                <Fade bottom>
                  <Title sm>Yhdistelmäkoulutus</Title>
                  <p className="mb-8 font-bold">3 päivää - 1250€</p>
                  <p className="mb-8">
                    Koulutuksessa opettelemme tekemään klassiset, sekä
                    volyymiripset. Koulutus on todella intensiivinen ja teemme
                    uudet klassiset ja volyymiripset, sekä huoltokoulutus 4vkon
                    päähän ensimmäisestä koulutuspäivästä. Sisältää
                    aloituspakkauksen sekä kouluttajan tuen myös koulutuksen
                    jälkeen.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Trainings;
