import { Link, useLocation } from "react-router-dom";

function MenuLink({ path, title, bold, ...rest }) {
  const location = useLocation();

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  };
  if (path === "/booking") {
    return (
      <li>
        <a
          href={path}
          className={`menu-link ${pathMatchRoute(path) ? `menu-active` : ""}${
            bold ? " font-bold" : ""
          }`}
          {...rest}
        >
          {title}
        </a>
      </li>
    );
  }
  return (
    <li>
      <Link
        to={path}
        className={`menu-link ${pathMatchRoute(path) ? `menu-active` : ""}${
          bold ? " font-bold" : ""
        }`}
        {...rest}
      >
        {title}
      </Link>
    </li>
  );
}

export default MenuLink;
