import React from "react";
import underline from "../images/title-underline.png";

function Title({ children, sm, centered }) {
  return (
    <h3
      className={`title ${
        sm ? `md:text-4xl text-2xl` : `md:text-5xl text-3xl`
      }${centered ? " vertically-centered" : ""}`}
    >
      {children}
    </h3>
  );
}

export default Title;
