import React from "react";
import { Link } from "react-router-dom";
import ContactInformation from "../components/ContactInformation";
import Container from "../components/Container";
import Title from "../components/Title";

function Footer() {
  return (
    <footer className='text-black'>
      <div className='container mx-auto p-12'>
        <div className='grid md:grid-cols-3 w-full grid-cols-1'>
          <div className='mb-5'>
            <div className='grid text-left'>
              <h2 className='font-playfair text-3xl mb-3'>Navigoi</h2>
              <div>
                <Link to='/' className='link link-hover w-auto'>
                  Etusivu
                </Link>
              </div>
              <div>
                <Link to='/booking' className='link link-hover'>
                  Ajanvaraus
                </Link>
              </div>
              <div>
                <Link to='/trainings' className='link link-hover'>
                  Koulutukset
                </Link>
              </div>
              <div>
                <Link to='/pricing' className='link link-hover'>
                  Hinnasto
                </Link>
              </div>
              <div>
                <Link to='/about' className='link link-hover'>
                  Tietoa minusta
                </Link>
              </div>
              <div>
                <Link to='/contact' className='link link-hover'>
                  Yhteystiedot
                </Link>
              </div>
            </div>
          </div>
          <div className='mb-5'>
            <h2 className='font-playfair text-3xl mb-3'>Sosiaalinen media</h2>
            <div>
              <p>
                <a
                  href='https://www.facebook.com/adelianbeauty'
                  className='link link-hover'
                  target='_blank'
                >
                  Facebook
                </a>
              </p>
              <p>
                <a
                  href='https://www.instagram.com/adelianbeauty/'
                  className='link link-hover'
                  target='_blank'
                >
                  Instagram
                </a>
              </p>
            </div>
          </div>
          <div>
            <h2 className='font-playfair text-3xl mb-3'>Yhteystiedot</h2>
            <ContactInformation />
          </div>
        </div>
      </div>
      <div className='text-center w-full border-t-1 border-t-gray-200 p-5 mt-5'>
        Copyright © Adelia'n Beauty {new Date().getFullYear()}
      </div>
    </footer>
  );
}

export default Footer;
