import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './views/Home';
import NotFound from './views/NotFound';
import CookieConsent from 'react-cookie-consent';
import About from './views/About';
import Contact from './views/Contact';
import Booking from './views/Booking';
import Pricing from './views/Pricing';
import Trainings from './views/Trainings';
import ScrollToTop from './components/ScrollToTop';
import { gsap, Power3, Power4 } from 'gsap';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/booking' element={<Booking />} />
          <Route path='/trainings' element={<Trainings />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
