import React from 'react';
import Container from '../components/Container';
import Title from '../components/Title';

function NotFound() {
  return (
    <Container>
      <Title>Hupsista!</Title>
      <p>Jotain meni pieleen, valitsemaasi sivua ei löytynyt.</p>
    </Container>
  );
}

export default NotFound;
