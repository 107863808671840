import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Hero from "./Hero";
import Navbar from "./Navbar";

function Layout(props) {
  const { children } = props;
  const location = useLocation();

  return (
    <div className="h-full">
      <div
        className="booksalon-widget"
        data-salon-id="adelian-beauty"
        data-widget-position="overlay"
      ></div>
      <header className={location.pathname === "/" ? "show-jumbo" : ""}>
        <Navbar />
        {location.pathname === "/" && (
          <>
            <Hero />
            <div className="carousel-wrapper">
              {/* <img src={arrowLeft} className='arrow-left' />
              <img src={arrowRight} className='arrow-right' /> */}
              <div className="carousel-actions">
                {/* <img src={circle} className='mr-3' />
                <img src={circle} className='mr-3 opacity-20' />
                <img src={circle} className='opacity-20' /> */}
              </div>
            </div>
          </>
        )}
      </header>
      <main
        className={`bg-default${location.pathname !== "/" ? " mb-extra" : ""}`}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
